import React from "react"

import Container from "react-bootstrap/Container"

import FormsService from "../service/formsservice"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./demo.scss"
import FlourishingCta from "../components/flourishing-cta/flourishing-cta"

export default class Demo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      submitting: false,
      emailError: false,

      formData: {},
    }
  }

  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    this.setState((oldState) => {
      return {
        ...oldState,
        formData: {
          ...oldState.formData,
          [name]: value,
        },
      }
    })
  }

  validateEmail = (email) => {
    if (/^[a-zA-Z0-9.'_%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      this.setState({ emailError: false })
      return true
    } else {
      this.setState({ emailError: true })
      return false
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if (this.validateEmail(this.state.formData.email)) {
      const form = e.target
      this.setState({ submitting: true })

      let data = JSON.stringify(this.state.formData)
      FormsService.demoForm(data)

        .then((res) => {
          if (res) {
            this.setState({ submitted: true })
          }
        })
        .catch((error) => console.log("Error: ", error))
        .finally(() => {
          this.setState({ submitting: false })
        })
    }
  }
  componentDidMount() {
    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    )
    head.appendChild(script)
  }

  render() {
    return (
      <Layout>
        <SEO title="Demo" />
        <div className="outer-wrapper-demo">
          <Container className="customContainerDemo">
            <div className="inner-wrapper-demo">
              <h1>Book a Call</h1>
              <p>
                Book a demo and witness first-hand the power of flourishing at
                work.
              </p>

              <p className="hidden">
                <label>
                  Don’t fill this out if you're human:{" "}
                  <input name="bot-field" />
                </label>
              </p>

              <div
                class="calendly-inline-widget"
                data-url="https://calendly.com/alikhan-shape/30min"
                style={{ minWidth: 320, height: 700 }}
              ></div>
            </div>
          </Container>
        </div>
        <FlourishingCta page={"demo"} />
      </Layout>
    )
  }
}
